<template>
  <div class="manse-format-view">
    <div v-if="manse">
      <v-row>
        <v-col>
          {{ manse.user.birthday }}({{ manse.user.age }}세, <span v-if="manse.user.gender === 'male'">남</span
          ><span v-else>여</span>, <span v-if="manse.user.birthdayType === 'SOLAR'">양력</span><span v-else>음력</span>)
          <span v-if="manse.user.time"> {{ manse.user.time }}</span>
        </v-col>
      </v-row>
      <!-- <div class="name">사주팔자</div> -->
      <saju-format-view :saju="manse.saju" />
      <v-row>
        <!-- <div class="name">대운 (10년)</div> -->
        <big-fortune-format-view
          :bigFortune="manse.list.bigFortune"
          :bigNumber="number"
          :memberId="memberId"
          :userId="userId"
        />

        <!-- <div class="name">세운 (1년)</div> -->
        <small-fortune-format-view
          :smallFortune="manse.list.smallFortune"
          :age="manse.user.age"
          :number="number"
          :memberId="memberId"
          :userId="userId"
        />

        <!-- <div class="name">월운</div> -->
        <month-fortune-format-view :monthFortune="manse.list.monthFortune" />
      </v-row>
    </div>
  </div>
</template>

<script>
import SajuFormatView from "../format/SajuFormatView";
import BigFortuneFormatView from "../format/BigFortuneFormatView";
import SmallFortuneFormatView from "../format/SmallFortuneFormatView";
import MonthFortuneFormatView from "../format/MonthFortuneFormatView";

export default {
  name: "ManseFormatView",
  components: {
    SajuFormatView,
    BigFortuneFormatView,
    SmallFortuneFormatView,
    MonthFortuneFormatView,
  },
  props: {
    manse: {
      type: Object,
    },
    number: {
      type: Number,
    },
    memberId: {
      type: Number,
    },
    userId: {
      type: Number,
    },
  },
};
</script>
