<template>
  <tr class="comment-list-view" v-if="type == comment.type">
    <td>{{ comment.typeName }} ({{ comment.type }})</td>
    <td>{{ comment.codeName }} ({{ comment.code }})</td>
    <td>
      <comment-update-form @commentUpdate="onClickCommentUpdate" :propComment="comment" />
    </td>
    <td>
      {{ comment.comment }}
    </td>
  </tr>
</template>

<script>
import { mapActions } from "vuex";
import CommentUpdateForm from "./CommentUpdateForm";

export default {
  name: "CommentListView",
  components: {
    CommentUpdateForm,
  },
  props: {
    comment: {
      type: Object,
    },
    type: {
      type: String,
    },
  },
  methods: {
    async onClickCommentUpdate(payload) {
      await this.updateComment(payload);
      setTimeout(() => this.getComments(), 1000);
    },
    ...mapActions(["updateComment", "getComments"]),
  },
};
</script>
