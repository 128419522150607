<template>
  <div class="dashboard-page">
    <span>대시보드 ({{ new Date().toLocaleDateString() }})</span>
    <v-row justify="center" align="center">
      <table>
        <tbody>
          <td>
            <tr>
              <td>
                <p>오늘 DAU ({{ dashboard.content.TODAY_DAU }}명)</p>
              </td>
              <td>
                <p>이번달 MAU ({{ dashboard.content.THIS_MAU }}명)</p>
              </td>
            </tr>
            <tr>
              <td>총 회원수 ({{ dashboard.content.allCount }}명)</td>
              <td>
                <p>남자 회원 ({{ dashboard.content.allManCount }}명)</p>
                <p></p>
                <p>여자 회원 ({{ dashboard.content.allWomanCount }}명)</p>
              </td>
            </tr>
            <tr>
              <td>20대 회원수 ({{ dashboard.content.ages20Count }}명, {{ dashboard.content.ages20Ratio }}%)</td>
              <td>
                <p>20대 남자 회원수 ({{ dashboard.content.ages20ManCount }}명)</p>
                <p>20대 여자 회원수 ({{ dashboard.content.ages20WomanCount }}명)</p>
              </td>
            </tr>
            <tr>
              <td>30대 회원수 ({{ dashboard.content.ages30Count }}명, {{ dashboard.content.ages30Ratio }}%)</td>
              <td>
                <p>30대 남자 회원수 ({{ dashboard.content.ages30ManCount }}명)</p>
                <p>30대 여자 회원수 ({{ dashboard.content.ages30WomanCount }}명)</p>
              </td>
            </tr>
            <tr>
              <td>오늘 가입자수 ({{ dashboard.content.todayRegistCount }}명)</td>
              <td>어제 가입자수 ({{ dashboard.content.yesterdayRegistCount }}명)</td>
            </tr>
            <tr>
              <td>이번주 가입자수 ({{ dashboard.content.thisWeekRegistCount }}명)</td>
              <td>지난주 가입자수 ({{ dashboard.content.lastWeekRegistCount }}명)</td>
            </tr>

            <tr>
              <td>이번달 가입자수 ({{ dashboard.content.thisMonthRegistCount }}명)</td>
              <td>지난달 가입자수 ({{ dashboard.content.lastMonthRegistCount }}명)</td>
            </tr>
          </td>
        </tbody>
      </table>
    </v-row>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  name: "DashboardPage",
  async created() {
    await this.getDashboard(this);
  },
  methods: {
    ...mapActions(["getDashboard"]),
  },
  computed: {
    ...mapState(["dashboard"]),
  },
};
</script>

<style scoped>
.dashboard-page {
  margin: 10px;
}
table {
  border-collapse: collapse;
  width: 500px;
  border: 1px solid black;
  margin: 5px;
}

th,
td {
  width: 250px;
  border: 1px solid black;
  padding: 15px;
}
</style>
