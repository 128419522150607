import {
  SET_ACCESS_TOKEN,
  SET_MY_INFO,
  DESTROY_ACCESS_TOKEN,
  DESTROY_MY_INFO,
  SET_USERS,
  SET_DASHBOARD,
  SET_COMMENTS,
  SET_SAJU,
  SET_MANSE,
} from "./mutations-types";
import api from "@/api";

export default {
  /**
   * [인증]
   */
  //로그인
  async signin({ commit }, payload) {
    const { email, password } = payload;
    commit(DESTROY_MY_INFO);
    commit(DESTROY_ACCESS_TOKEN);
    try {
      const res = await api.post("/admin/signin", {
        email,
        password,
      });

      if (!res) {
        alert("아이디 또는 패스워드 입력이 잘못되었습니다.");
      }

      const { accessToken } = res.data;
      commit(SET_ACCESS_TOKEN, accessToken);

      try {
        const res = await api.get("/admin/me");
        if (res) {
          commit(SET_MY_INFO, res.data);
        }
      } catch (error) {
        this.router.push({ name: "SignInPage" });
      }
    } catch (error) {
      alert("로그인 에러");
    }
  },

  async signinByToken({ commit }, token) {
    commit(SET_ACCESS_TOKEN, token);
    try {
      return await api.get("/admin/me").then((res) => {
        commit(SET_MY_INFO, res.data);
      });
    } catch (error) {
      this.router.push({ name: "SignInPage" });
    }
  },

  //로그아웃
  signout({ commit }) {
    commit(DESTROY_MY_INFO);
    commit(DESTROY_ACCESS_TOKEN);
  },
  //회원 리스트
  async getUsers({ commit }, payload) {
    try {
      let { page, typeSort } = payload;
      page -= 1;
      const res = await api.get(`admin/users?page=${page}&size=20&type=${typeSort}`);
      if (res) {
        return commit(SET_USERS, res.data);
      }
    } catch (err) {
      return alert("회원 리스트 에러");
    }
  },

  //대시보드
  async getDashboard({ commit }) {
    try {
      const res = await api.get(`admin/dashboard`);
      if (res) {
        return commit(SET_DASHBOARD, res.data);
      }
    } catch (err) {
      return alert("대시보드 가져오기 에러");
    }
  },

  //사주 통변
  async getComments({ commit }) {
    try {
      const res = await api.get(`admin/comments`);
      if (res) {
        return commit(SET_COMMENTS, res.data);
      }
    } catch (err) {
      return alert("사주 통변 리스트 에러");
    }
  },

  //통변 수정
  async updateComment({ commit }, payload) {
    commit;
    const { id, comment } = payload;
    try {
      return await api.patch(`/admin/comment/${id}`, {
        comment,
      });
    } catch (err) {
      return alert("통변 수정 에러");
    }
  },

  // //통변DB 캐시 처리
  // async setCache({ commit }) {
  //   commit;
  //   try {
  //     return await api.get(`/admin/comment/cache`);
  //   } catch (err) {
  //     return alert("통변 DB 캐시 처리 에러");
  //   }
  // },

  //사주
  async getSaju({ commit }, payload) {
    try {
      const { userId, memberId, year, month, day } = payload;
      const res = await api.get(`/admin/saju/${userId}/${memberId}/year/${year}/${month}/${day}`);
      if (res) {
        return commit(SET_SAJU, res.data.data);
      }
    } catch (err) {
      if (err.response.status === 400) {
        return alert("잘못된 memberId, year, month, day 를 입력하였습니다.");
      } else if (err.response.status === 401) {
        return alert("해당 멤버의 사주를 가져올 수 없습니다.");
      } else {
        return alert("사주 가져오기 에러");
      }
    }
  },

  //만세력
  async getManse({ commit }, payload) {
    try {
      const { userId, memberId, number, number2 } = payload;
      let res = null;

      if (number && number2) {
        res = await api.get(`/admin/manse/${userId}/${memberId}/${number}/${number2}`);
      } else if (number) {
        res = await api.get(`/admin/manse/${userId}/${memberId}/${number}`);
      } else {
        res = await api.get(`/admin/manse/${userId}/${memberId}`);
      }
      if (res) {
        return commit(SET_MANSE, res.data.data);
      }
    } catch (err) {
      return alert("만세력 가져오기 에러");
    }
  },
};
