import {
  SET_ACCESS_TOKEN,
  SET_MY_INFO,
  DESTROY_ACCESS_TOKEN,
  DESTROY_MY_INFO,
  SET_USERS,
  SET_DASHBOARD,
  SET_COMMENTS,
  SET_SAJU,
  SET_MANSE,
} from "./mutations-types";
import api from "@/api";
import Cookies from "js-cookie";

export default {
  [SET_ACCESS_TOKEN](state, accessToken) {
    if (accessToken) {
      state.accessToken = accessToken;
      api.defaults.headers.common["Authorization"] = `bearer ${accessToken}`;

      Cookies.set("accessToken", accessToken);
    }
  },
  [SET_MY_INFO](state, me) {
    if (me) {
      state.me = me;
    }
  },
  [DESTROY_ACCESS_TOKEN](state) {
    state.accessToken = "";
    delete api.defaults.headers.common["Authorization"];
    Cookies.remove("accessToken");
  },
  [DESTROY_MY_INFO](state) {
    state.me = null;
  },
  [SET_USERS](state, users) {
    if (users) {
      state.users = users;
    }
  },
  [SET_DASHBOARD](state, dashboard) {
    if (dashboard) {
      state.dashboard = dashboard;
    }
  },
  [SET_COMMENTS](state, comments) {
    if (comments) {
      state.comments = comments;
    }
  },
  [SET_SAJU](state, saju) {
    if (saju) {
      state.saju = saju;
    }
  },
  [SET_MANSE](state, manse) {
    if (manse) {
      state.manse = manse;
    }
  },
};
