<template>
  <div class="comment-page">
    <span>통변 관리</span><v-spacer></v-spacer><v-btn outlined @click="onClickCache">통변DB 캐시</v-btn>

    <v-radio-group v-model="type" row>
      <v-radio label="2022년 운세" value="壬寅_year"></v-radio>
      <v-radio label="2021년 운세" value="辛丑_year"></v-radio>
      <!-- <v-radio label="십성 위치" value="tenstar_position"></v-radio> -->
      <v-radio label="십성 개수" value="tenstar_count"></v-radio>
      <v-radio label="오행 개수" value="fivecircle_count"></v-radio>
      <!-- <v-radio label="일간" value="daysky"></v-radio> -->
      <!-- <v-radio label="월지" value="monthground"></v-radio> -->
      <!-- <v-radio label="년지" value="yearground"></v-radio> -->
      <!-- <v-radio label="일간 + 월지" value="daysky_monthground"></v-radio> -->
      <!-- <v-radio label="월주" value="monthju"></v-radio> -->
      <v-radio label="일주" value="dayju"></v-radio>
      <v-radio label="천간합" value="sky_merge"></v-radio>
      <v-radio label="천간극" value="sky_conflict"></v-radio>
      <v-radio label="육합" value="six_merge"></v-radio>
      <v-radio label="삼합" value="three_merge"></v-radio>
      <v-radio label="반합" value="half_merge"></v-radio>
      <v-radio label="지지충" value="ground_conflict"></v-radio>
      <v-radio label="삼형살" value="three_sin"></v-radio>
      <v-radio label="이형살" value="two_sin"></v-radio>
      <v-radio label="자형살" value="self_sin"></v-radio>
      <v-radio label="원진" value="resent"></v-radio>
      <v-radio label="귀문" value="ghostdoor"></v-radio>
      <v-radio label="원진귀문" value="resent_ghostdoor"></v-radio>
      <v-radio label="천라지망" value="sky_ground_net"></v-radio>
      <!-- <v-radio label="십성 운세" value="tenstar_fortune"></v-radio> -->
    </v-radio-group>
    <v-row justify="center" align="center">
      <v-simple-table style="width: 90%" dense>
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left" width="100px">타입</th>
              <th class="text-left" width="100px">코드</th>
              <th class="text-left" width="100px"></th>
              <th class="text-left" width="800px">설명</th>
            </tr>
          </thead>

          <tbody v-if="comments">
            <comment-list-view v-for="comment in comments.content" :key="comment.id" :comment="comment" :type="type" />
          </tbody>
        </template>
      </v-simple-table>
    </v-row>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import CommentListView from "./CommentListView";

export default {
  name: "commentPage",
  components: {
    CommentListView,
  },
  data() {
    return {
      type: "tenstar_count",
    };
  },
  watch: {
    type: {
      deep: true,
      async handler() {
        await this.getComments();
      },
    },
  },
  async created() {
    await this.getComments();
  },
  methods: {
    onClickCache() {
      this.setCache().then(() => {
        alert("통변DB 캐시 성공");
      });
    },
    ...mapActions(["getComments", "setCache"]),
  },
  computed: {
    ...mapState(["comments"]),
  },
};
</script>

<style scoped>
.comment-page {
  margin: 10px;
}
</style>
