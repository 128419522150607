<template>
  <div>
    <v-dialog v-model="dialog" persistent max-width="700px">
      <template v-slot:activator="{ on }">
        <v-row justify="center" align="center">
          <v-btn outlined small v-on="on">수정</v-btn>
        </v-row>
      </template>
      <v-card>
        <v-form ref="form">
          <v-card-text>
            <v-container>
              <v-row>{{ this.propComment.typeName }}({{ this.propComment.codeName }})</v-row>
              <v-row>
                <v-col cols="12" sm="12" md="12">
                  <v-textarea label="설명" v-model="comment" rows="15"></v-textarea>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn outlined text @click="onClickCommentUpdateCancel">취소</v-btn>
            <v-btn outlined text @click="onClickCommentUpdate">수정</v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "CommentUpdateForm",
  data() {
    return {
      dialog: false,
      id: this.propComment.id,
      comment: this.propComment.comment,
    };
  },
  props: {
    propComment: {
      type: Object,
    },
  },
  methods: {
    onClickCommentUpdateCancel() {
      this.resetFields();
      this.dialog = false;
    },
    onClickCommentUpdate() {
      if (this.$refs.form.validate() == false) {
        return;
      }
      this.dialog = false;
      this.$emit("commentUpdate", this);
    },
    resetFields() {
      this.comment = this.propComment.comment;
    },
  },
};
</script>
