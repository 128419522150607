import Vue from "vue";
import Router from "vue-router";
import DashboardPage from "@/components/dashboard/DashboardPage";
import SignInPage from "@/components/auth/SignInPage";
import UserPage from "@/components/user/UserPage";
import CommentPage from "@/components/comment/CommentPage";

Vue.use(Router);

export default new Router({
  mode: "history",
  routes: [
    //대시보드
    {
      path: "/",
      component: DashboardPage,
      name: "DashboardPage",
    },
    //로그인
    {
      path: "/signin",
      component: SignInPage,
      name: "SignInPage",
    },
    //회원
    {
      path: "/user",
      component: UserPage,
      name: "UserPage",
    },
    // 통변
    {
      path: "/comment",
      component: CommentPage,
      name: "CommentPage",
    },
  ],
});
