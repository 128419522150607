<template>
  <tr class="user-list-view">
    <td>{{ index }}</td>
    <td v-if="user.Member.gender === 'male'">남</td>
    <td v-else>여</td>
    <td>{{ user.Member.age }}세</td>
    <td>
      {{ user.Member.birthday }}(<span v-if="user.Member.birthdayType === 'SOLAR'">양력</span><span v-else>음력</span>)
      {{ user.Member.time }}
    </td>
    <td>
      <v-btn justify-center outlined x-small @click="onClickSaju">사주</v-btn
      ><v-btn justify-center outlined x-small @click="onClickManse">만세력</v-btn>
    </td>
    <td>{{ user.User.memberCount }}명</td>
    <td>{{ user.User.loggedAt }}</td>
    <td>{{ user.createdAt }}</td>
  </tr>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "UserListView",
  props: {
    user: {
      type: Object,
    },
    index: {
      type: Number,
    },
  },
  methods: {
    onClickSaju() {
      this.userId = this.user.UserId;
      this.memberId = this.user.Member.id;
      this.type = "SAJU";
      this.dialog = true;
      this.$emit("emitSaju", this);
    },
    onClickManse() {
      this.userId = this.user.UserId;
      this.memberId = this.user.Member.id;
      this.type = "MANSE";
      this.dialog = true;
      this.$emit("emitManse", this);
    },
    ...mapActions(["getUsers"]),
  },
};
</script>
